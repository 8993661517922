<template>
    <layout-div>
        <div class="card">
            <div class="card-body">
                <VueMarkdownIt :source="content"></VueMarkdownIt>               
            </div>
        </div>
    </layout-div>
</template>

<script>
import LayoutDiv from '@/components/LayoutDiv.vue';
import { gethome } from '@/api/Authentication.js';
import VueMarkdownIt from "vue3-markdown-it";

export default {
    name: 'home',
    components: {
        LayoutDiv,
        VueMarkdownIt
    },
    data() {
        return {
            content: ''
        };
    },
    created() {
        gethome()
            .then(rep => {
                this.content = rep.data || '# No Content Available';
            })
            .catch(error => {
                console.error('Error fetching content:', error);
                this.content = '# Error loading content';
            });
    },
    methods: {

    }
};
</script>